import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  all(): Observable<any> {
    return this.http.get(`${this.endpoint}/api/category`, {
      headers: this.headers,
    });
  }
}
