import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../api/services/authentication.service";
import {environment} from "../../../../../environments/environment";
import {ErrorService} from "../../../../services/error.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  formErrorMessage = {
    email: 'Anda telah memasukkan alamat email yang tidak valid',
  }

  form!: FormGroup;
  loading = false;
  submitted = false;
  isSubmitted = false;

  errors = this.formErrorMessage;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
          email: ['', [Validators.required, Validators.email]]
      })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.loading = true;
    this.submitted = true;

    if (this.form?.invalid) {
      this.loading = false;
      return;
    }

    this.authenticationService.forgotPassword({
      ...this.form.value,
      url: environment.appUrl+'/auth/reset-password'
    }).subscribe({
      next: (res) => {
        this.loading = false;
        this.isSubmitted = true;
      },
      error: (e) => {
        if (e.error.errors) {
          const errors = this.errorService.transformToKeyValue(e.error.errors)

          this.errors = {
            ...this.errors,
            ...errors
          }

          Object.keys(errors).map((objectKey) => {
            this.form.controls[objectKey].setErrors({'incorrect': true});
          });
        }

        this.loading = false;
      }
    })
  }
}
