import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseParams } from '../models/base-param';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  all(params: BaseParams): Observable<any> {
    return this.http.get(`${this.endpoint}/api/article`, {
      headers: this.headers,
      params: {
        ...this.cleanParams(params),
        with: ['photo'],
      },
    });
  }

  get(permalink: string): Observable<any> {
    return this.http.get(`${this.endpoint}/api/article/${permalink}`);
  }

  highlight(): Observable<any> {
    return this.http.get(`${this.endpoint}/api/article-highlight`);
  }
}
