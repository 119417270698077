import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

// import Swiper core and required modules
import SwiperCore, { Autoplay, SwiperOptions, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { Product } from '../../../api/models/product';

// install Swiper modules
SwiperCore.use([Virtual, Autoplay]);

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss'],
})
export class ShowcaseComponent implements OnInit {
  @Input() isLoading = false;
  @Input() title = '';
  @Input() list: Array<Product> = [];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  config: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 20,
    navigation: true,
    autoplay: true,
    pagination: {
      enabled: false,
    },
    scrollbar: {
      draggable: true,
    },
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 6,
        spaceBetween: 25,
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  slideNext() {
    // @ts-ignore
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev() {
    // @ts-ignore
    this.swiper.swiperRef.slidePrev(100);
  }
}
