import { Component, OnInit } from '@angular/core';
import {CountdownConfig, CountdownEvent} from "ngx-countdown";
import { ActivatedRoute, Router } from '@angular/router';
import {AuthenticationService} from "../../../../../api/services/authentication.service";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 5,
    formatDate: ({ date }) => `${date / 1000}`,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        let token = params['token']
        this.authenticationService.verify(token).subscribe({
          next: (res) => {
            console.log(res)
          },
          error: (e) => {
            console.log(e)
            this.router.navigate(['/'])
          }
        })
      });
  }

  endCountdown(e: CountdownEvent) {
    if (e.status === 3) {
      this.router.navigate(['/auth/login']);
    }
  }
}
