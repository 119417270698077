import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {BaseParams} from "../models/base-param";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService extends BaseService{
  constructor(private http: HttpClient) {
    super();
  }

  all(params?: BaseParams): Observable<any> {
    return this.http.get(`${this.endpoint}/api/payment-method`, {
      headers: this.headers,
      params: {
        ...this.cleanParams(params),
        with: ['photo'],
      },
    });
  }
}
