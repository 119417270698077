import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../../services/meta.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  active = 'account';

  account = {};
  constructor(
    private metaService: MetaService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.metaService.updateTitle('Setting');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.active = params['tab'];
    });
  }

  onNavChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: this.active },
      queryParamsHandling: 'merge',
    });
  }
}
