import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

const endpoint = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  register(data: any): Observable<any> {
    return this.http.post(`${endpoint}/api/register`, data);
  }

  verify(token: string): Observable<any> {
    return this.http.post(`${endpoint}/api/verify`, {
      token: token
    });
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post(`${endpoint}/api/forgot-password`, data);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${endpoint}/api/reset-password`, data);
  }
}
