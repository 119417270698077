import { Injectable } from '@angular/core';
import { User } from './user';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import {AccountService} from "../../api/services/account.service";
import * as moment from "moment";
import {RoutingService} from "../../services/routing.service";
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = environment.apiUrl;
  clientId: string = environment.clientId;
  clientSecret: string = environment.clientSecret;

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};
  constructor(
    private http: HttpClient,
    public router: Router,
    private accountService: AccountService,
    private routingService: RoutingService
  ) {}
  // Sign-up
  signUp(user: User): Observable<any> {
    let api = `${this.endpoint}/register-user`;
    return this.http.post(api, user).pipe(catchError(this.handleError));
  }
  // Sign-in
  signIn(user: User): Observable<any> {
    return this.http
      .post<any>(`${this.endpoint}/oauth/token`, {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        grant_type: 'password',
        merchant_id: environment.merchantId,
        ...user
      }, {
          headers: {skip: "true"}
        }
      )
  }
  getToken() {
    const accessToken = window.localStorage.getItem('access_token');

    if (!accessToken) {
      return false;
    }

    const expiredAt = window.localStorage.getItem('expires_at');

    if(expiredAt) {
      const exp = moment(expiredAt, 'YYYY-MM-DD HH:mm:ss')

      if(exp <= moment()){
        return false;
      } else {
        let diff = moment.duration(exp.diff(moment()));
        let ms = Math.ceil(diff.asMilliseconds())
        setTimeout(async () => {
          if(exp <= moment()){
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('expires_at');
            window.location.reload()
          }
        }, ms)
      }
    }

    return accessToken;
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }
  doLogout() {
    this.accountService.logout().subscribe({
      next: () => {

      },
      error: () => {

      }
    })

    this.routingService.removeHistory()
    let removeToken = localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('expires_at');
    if (removeToken == null) {
      this.router.navigate(['/auth/login']);
    }
  }
  // User profile
  getUserProfile(id: any): Observable<any> {
    let api = `${this.endpoint}/user-profile/${id}`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res) => {
        return res || {};
      }),
      catchError(this.handleError)
    );
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
