import { Component, Input, OnInit } from '@angular/core';
import { Why } from '../../../api/models/why';

@Component({
  selector: 'app-why-info',
  templateUrl: './why-info.component.html',
  styleUrls: ['./why-info.component.scss'],
})
export class WhyInfoComponent implements OnInit {
  @Input() data: Why[] = [];

  constructor() {}

  ngOnInit(): void {}
}
