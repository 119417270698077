import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseParams } from '../../../api/models/base-param';
import { Product } from '../../../api/models/product';
import { ProductService } from '../../../api/services/product.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  results: Product[] = [];
  resultParams: BaseParams = {
    per_page: 24,
    page: 1,
    search: '',
  };
  totalData: number = 0;
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.resultParams.search = params['search'];
    });

    if (!this.resultParams.search) this.router.navigate(['/']);

    this.loadResults();
  }

  loadResults = (isLoadMore = false) => {
    this.productService.all(this.resultParams).subscribe({
      next: (res) => {
        const { data, meta } = res;
        if (isLoadMore) {
          this.results = [...this.results, ...(data as Product[])];
        } else {
          this.results = data as Product[];
          this.isLoading = false;
        }
        this.totalData = meta.total;
      },
      complete: () => {},
    });
  };

  disabledShowMore = (): boolean => {
    const diff =
      this.totalData - this.resultParams.per_page * this.resultParams.page;
    return diff <= 0;
  };

  loadMore(): void {
    this.resultParams.page += 1;
    this.loadResults(true);
  }
}
