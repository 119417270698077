<ng-container>
  <div class="app-wrapper">
    <app-header></app-header>

    <div class="app-body">
      <a [href]="data.whatsapp_link" target="_blank" class="floating-chat">
        <img class="icon" src="assets/images/icon/chat.png" alt="chat icon">
      </a>
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
  </div>
</ng-container>
