import {Component, OnDestroy, OnInit} from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { untilDestroyed } from "ngx-take-until-destroy";
import { ShowHideService } from "ngx-show-hide-password";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../../../shared/auth/auth.service";
import * as moment from "moment";
import {RoutingService} from "../../../../services/routing.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  isHidden = true;

  form!: FormGroup;
  loading = false;
  submitted = false;

  errorMessage = '';

  constructor(
    private service: ShowHideService,
    private fb: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private routingService: RoutingService
  ) {
    this.service
      .getObservable("password")
      .pipe(untilDestroyed(this))
      .subscribe(show => {
        this.isHidden = !show;
      });
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        username: ['', [Validators.required, Validators.email]],
        password: ['',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(40)
          ]
        ]
      }
    )
  }

  ngOnDestroy(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.loading = true;
    this.submitted = true;
    if (this.form?.invalid) {
      this.loading = false;
      return;
    }

    this.authService.signIn(this.form.value)
      .subscribe((res: any) => {
        const expiredAt = moment().add(res.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');

        this.loading = false;
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('refresh_token', res.refresh_token);
        localStorage.setItem('expires_in', String(res.expires_in));
        localStorage.setItem('expires_at', String(expiredAt));

        if (!this.routingService.getPreviousUrl().includes('auth')) {
          this.router.navigate([this.routingService.getPreviousUrl()]);
        } else {
          this.router.navigate(['/']);
        }

      }, error => {
        this.loading = false;
        this.errorMessage = error.error.message;
      });
  }
}
