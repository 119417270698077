import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-game',
  templateUrl: './card-game.component.html',
  styleUrls: ['./card-game.component.scss']
})
export class CardGameComponent implements OnInit {
  @Input() name: string = '';
  @Input() image?: string | null = '';
  @Input() link?: string | null = '';
  @Input() isRelease: Boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
