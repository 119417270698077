import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SelectService extends BaseService{

  constructor(private http: HttpClient) {
    super();
  }

  server(id: number, provider: string = 'goc'): Observable<any> {
    return this.http.get(`${this.endpoint}/api/select/server`, {
      headers: this.headers,
      params: {
        product: id,
        provider: provider
      }
    })
  }
}
