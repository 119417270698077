<a [routerLink]="link" class="card-news">
  <div class="image-wrapper">
    <img class="image" [src]="image" alt="article 1">
  </div>

  <div class="info-wrapper">
    <h4 class="title">
      {{title}}
    </h4>
    <span class="timer">{{timer | date}}</span>
  </div>
</a>
