import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { PublicLayoutComponent } from './components/layouts/public-layout/public-layout.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { AuthLayoutComponent } from './components/pages/auth/auth-layout/auth-layout.component';
import { RegisterComponent } from './components/pages/auth/register/register.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/auth/reset-password/reset-password.component';
import { SuccessComponent as RegisterSuccessComponent } from './components/pages/auth/register/success/success.component';
import { PurchaseComponent } from './components/pages/purchase/purchase.component';
import { ArticleComponent } from './components/pages/article/article.component';
import { ArticleDetailComponent } from './components/pages/article-detail/article-detail.component';
import { SettingComponent } from './components/pages/setting/setting.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ResultComponent } from './components/pages/result/result.component';
import { TermsAndConditionComponent } from './components/pages/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { AuthGuard } from './shared/auth/auth.guard';
import {GuestGuard} from "./shared/auth/quest.guard";

const publicRoutes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      {
        path: 'register',
        children: [
          { path: '', component: RegisterComponent },
          { path: 'success', component: RegisterSuccessComponent },
        ],
      },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
    canActivate: [GuestGuard],
  },
  {
    path: 'article',
    children: [
      { path: '', component: ArticleComponent },
      { path: ':permalink', component: ArticleDetailComponent },
    ],
  },
  {
    path: 'result',
    component: ResultComponent,
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'purchase/:permalink',
    component: PurchaseComponent,
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthGuard],
  },
];

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: publicRoutes,
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
