<ng-container>
  <div class="header-form">
    <h4 class="title">Buat Password Baru</h4>
    <p class="subtitle">
      Buat password baru untuk {{email}}
    </p>
  </div>

  <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label class="form-label">Buat Password Baru</label>

      <div class="password-input-wrapper">
        <input
          id="password"
          type="password"
          class="form-control"
          placeholder="Buat password baru"
          formControlName="password"
          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          showHideInput
        >
        <fa-icon
          class="icon"
          [icon]="isHidden ? faEye : faEyeSlash"
          showHideTrigger="password"
        ></fa-icon>
      </div>
      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
        <div *ngIf="f['password'].errors">Min. 8 karakter (terdiri dari huruf BESAR, kecil, dan angka)</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Ketik Ulang Password</label>

      <div class="password-input-wrapper">
        <input
          id="password-confirmation"
          type="password"
          class="form-control"
          placeholder="Ketik ulang konfirmasi password"
          formControlName="password_confirmation"
          [ngClass]="{ 'is-invalid': submitted && f['password_confirmation'].errors }"
          showHideInput
        >
        <fa-icon
          class="icon"
          [icon]="isHiddenConPassword ? faEye : faEyeSlash"
          showHideTrigger="password-confirmation"
        ></fa-icon>
      </div>

      <div *ngIf="submitted && f['password_confirmation'].errors" class="invalid-feedback">
        <div *ngIf="f['password_confirmation'].errors">Konfirmasi password tidak sesuai </div>
      </div>
    </div>

    <div class="full-btn-wrapper mt-4">
      <button type="submit" class="btn btn-main btn-full">
        <span *ngIf="!loading">Buat Password Baru</span>
        <div class="spinner-border" role="status" *ngIf="loading">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </form>
</ng-container>
