import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../api/services/page.service';
import { MetaService } from '../../../services/meta.service';
import { PagePrivacyPolicy } from '../../../api/models/page-privacy-policy';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  data: PagePrivacyPolicy = {
    meta_title: '',
    meta_keyword: '',
    meta_description: '',
    content: '',
    last_updated_at: '',
  };
  isLoadingPage = true;
  constructor(
    private pageService: PageService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.pageService.page('privacy-policy').subscribe({
      next: (res) => {
        this.data = res.data as PagePrivacyPolicy;
        this.metaService.updateTitle(this.data.meta_description);
        this.isLoadingPage = false;
      },
      error: (e) => {},
    });
  }
}
