import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShowHideService} from "ngx-show-hide-password";
import {untilDestroyed} from "ngx-take-until-destroy";
import Validation from "../../../../utils/validation";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../../api/services/authentication.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  isHidden = true;
  isHiddenConPassword = true;

  form!: FormGroup;
  loading = false;
  submitted = false;

  token: string = '';
  email: string = '';

  constructor(
    private service: ShowHideService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.service
      .getObservable("password")
      .pipe(untilDestroyed(this))
      .subscribe(show => {
        this.isHidden = !show;
      });

    this.service
      .getObservable("password-confirmation")
      .pipe(untilDestroyed(this))
      .subscribe(show => {
        this.isHiddenConPassword = !show;
      });
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        password: ['',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(40),
            Validation.strong
          ]
        ],
        password_confirmation: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'password_confirmation')]
      }
    )

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    })
  }

  ngOnDestroy(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.loading = true;
    this.submitted = true;
    if (this.form?.invalid) {
      this.loading = false;
      return;
    }

    this.authenticationService.resetPassword({
      email: this.email,
      token: this.token,
      ...this.form.value,
    }).subscribe({
      next: () => {
        this.loading = false;
        this.router.navigate(['/auth/login']);
      },
      error: (e) => {
        if (e.error.errors) {
          let errors = e.error.errors;

          Object.keys(errors).map((objectKey) => {
            this.form.controls[objectKey].setErrors({'incorrect': true});
          });
        }

        this.loading = false;
      }
    })
  }
}
