<ng-container>
  <div class="success-wrapper">
    <img class="icon" src="../../../../../../assets/images/icon/check.png" alt="check icon">

    <h3 class="title">
      Terima Kasih
    </h3>
    <p class="desc">
      Pendaftaran Berhasil!
    </p>

    <button type="button" class="btn btn-main mb-3">Proceed</button>

    <p class="auto-redirect">
      Redirecting in <span><countdown [config]="config" (event)="endCountdown($event)"></countdown></span> seconds..
    </p>
  </div>
</ng-container>
