<a [routerLink]="link" class="card-game">
  <div class="image-wrapper">
    <img class="image" [src]="isRelease && image ? image : 'assets/images/empty.png'" [alt]="name">
  </div>
  <div class="info-wrapper">
    <span class="title">
      {{ isRelease ? name : 'Akan Datang'}}
    </span>
  </div>
</a>
