import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../api/services/account.service';
import { BaseParams } from '../../../api/models/base-param';
import { OrderHistory } from '../../../api/models/order-history';
import { OrderStatus } from '../../../api/models/order-status';

export interface HistoryParams extends BaseParams {
  status?: number;
}

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
})
export class TransactionHistoryComponent implements OnInit {
  histories: OrderHistory[] = [];
  statuses: OrderStatus[] = [{ id: 0, label: 'Semua' }];
  selectedStatus: number = 0;
  totalData: number = 0;
  historyParams: HistoryParams = {
    per_page: 6,
    page: 1,
  };
  isLoading: boolean = true;

  constructor(private accountService: AccountService) {}

  loadHistory = (isLoadMore = false) => {
    const params: HistoryParams = {
      ...this.historyParams,
      status: this.selectedStatus || undefined,
      order_by: 'date',
      sorted_by: 'desc'
    };
    this.accountService.orderHistory(params).subscribe({
      next: (res) => {
        const { data } = res;
        if (isLoadMore) {
          this.histories = [...this.histories, ...(data as OrderHistory[])];
        } else {
          this.histories = data as OrderHistory[];
          this.isLoading = false;
        }
        this.totalData = res?.total ?? 0;
      },
      complete: () => {},
    });
  };

  loadStatus(): void {
    this.accountService.orderStatus().subscribe({
      next: (res) => {
        const { data } = res;
        this.statuses = [...this.statuses, ...data];
      },
      complete: () => {},
    });
  }

  ngOnInit(): void {
    this.loadStatus();
    this.loadHistory();
  }

  disabledShowMore = (): boolean => {
    const diff = this.totalData - this.historyParams.per_page * this.historyParams.page;
    return diff <= 0;
  };

  loadMore(): void {
    this.historyParams.page += 1;
    this.loadHistory(true);
  }

  changeStatus(status: number) {
    if (this.historyParams.page > 1) this.historyParams.page = 1;
    this.selectedStatus = status;
    this.isLoading = true;
    this.loadHistory();
  }
}
