<ng-container>
  <div class="setting-page">
    <div class="container">
      <div class="setting-wrapper">
        <div class="setting-navigation-wrapper">
          <h3 class="default-title">Pengaturan</h3>
          <div class="setting-navigation">
            <div class="navigation-item" [ngClass]="active === 'account' ? 'active' : ''" (click)="nav.select('account')">Akun</div>
            <div class="navigation-item" [ngClass]="active === 'order-history' ? 'active' : ''" (click)="nav.select('order-history')">Riwayat Transaksi</div>
          </div>
        </div>

        <ul ngbNav #nav="ngbNav" [(activeId)]="active" (activeIdChange)="onNavChange()" class="nav-pills">
          <li [ngbNavItem]="'account'">
            <ng-template ngbNavContent>
              <app-setting-account></app-setting-account>
            </ng-template>
          </li>
          <li [ngbNavItem]="'order-history'">
            <ng-template ngbNavContent>
              <app-transaction-history></app-transaction-history>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</ng-container>
