import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  endpoint = environment.apiUrl;

  cleanParams = (obj?: any): any => {
    if (!obj) return null;
    Object.keys(obj).forEach((key) =>
      obj[key] === undefined ? delete obj[key] : {}
    );
    return obj;
  };
}
