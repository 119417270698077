<ng-container>
  <div class="article-page">

    <app-spinner [isLoading]="isLoading">
      <div class="container">
        <div class="header-wrapper">
            <h3 class="default-title">Artikel</h3>
        </div>

        <div class="article-list">
          <app-card-news
            *ngFor="let article of articles"
            link="/article/{{article.permalink}}"
            [image]="article.photo_link"
            [title]="article.title"
            [timer]="article.date"
          ></app-card-news>
        </div>

        <div class="show-more" *ngIf="!disabledShowMore()">
          <button type="button" class="btn btn-outline-gray btn-lg" (click)="loadMore()">Muat lebih banyak</button>
        </div>
      </div>
    </app-spinner>


  </div>
</ng-container>

