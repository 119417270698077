<ng-container>
  <div class="search-result-page">

    <app-spinner [isLoading]="isLoading">
      <div class="container">
        <div class="search-result-wrapper">
          <div class="header-wrapper">
            <h1 class="default-title">Hasil pencarian untuk kata '{{resultParams.search}}'</h1>
          </div>

          <div class="search-result-list">
            <app-card-game
              *ngFor="let item of results"
              [name]="item.name"
              [image]="item.photo?.url ?? null"
              [link]="'/purchase/'+item.permalink"
            ></app-card-game>
          </div>

          <div class="show-more" *ngIf="!disabledShowMore()">
            <button type="button" class="btn btn-outline-gray btn-lg" (click)="loadMore()">Muat lebih banyak</button>
          </div>
        </div>
      </div>
    </app-spinner>
  </div>
</ng-container>
