<ng-container>
  <div class="terms-and-condition-page">
    <div class="container">
      <app-spinner [isLoading]="isLoadingPage">
        <div class="header-wrapper">
          <h1 class="default-title">Term & Conditions</h1>
          <div>Terakhir di update {{data.last_updated_at | date}}</div>
        </div>

        <hr class="divider" />

        <div class="body-wrapper" [innerHTML]="data.content">
        </div>
      </app-spinner>
    </div>
  </div>
</ng-container>
