<section class="setting-account-section">
  <div class="card">
    <h3 class="default-title">Email</h3>
    <div>{{account?.email}}</div>
    <hr class="divider">
    <h3 class="default-title">Password</h3>

    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label class="form-label">Password Lama</label>

        <div class="password-input-wrapper">
          <input
            id="old_password"
            type="password"
            class="form-control"
            placeholder="Masukkan password lama"
            formControlName="old_password"
            [ngClass]="{ 'is-invalid': submitted && f['old_password'].errors }"
            showHideInput
          >
          <fa-icon
            class="icon"
            [icon]="isHiddenOldPassword ? faEye : faEyeSlash"
            showHideTrigger="old_password"
          ></fa-icon>
        </div>
        <div *ngIf="submitted && f['old_password'].errors" class="invalid-feedback">
          <div *ngIf="f['old_password'].errors">{{this.errors.old_password || "Min. 8 karakter (terdiri dari huruf BESAR, kecil, dan angka)"}}</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Buat Password Baru</label>

        <div class="password-input-wrapper">
          <input
            id="new_password"
            type="password"
            class="form-control"
            placeholder="Buat password baru"
            formControlName="new_password"
            [ngClass]="{ 'is-invalid': submitted && f['new_password'].errors }"
            showHideInput
          >
          <fa-icon
            class="icon"
            [icon]="isHiddenNewPassword ?  faEye: faEyeSlash"
            showHideTrigger="new_password"
          ></fa-icon>
        </div>
        <div *ngIf="submitted && f['new_password'].errors" class="invalid-feedback">
          <div *ngIf="f['new_password'].errors">{{this.errors.new_password || "Min. 8 karakter (terdiri dari huruf BESAR, kecil, dan angka)"}}</div>
        </div>
      </div>

      <div class="mt-4">
        <button type="submit" class="btn btn-main">
          <span *ngIf="!loading">Ubah Password</span>
          <div class="spinner-border" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</section>
