import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  transformToKeyValue(errors: any) {
    const err = {}

    Object.keys(errors).map((objectKey, index) => {
      // @ts-ignore
      err[objectKey] = errors[objectKey][0]
    });

    return err;
  }
}
