import { Injectable } from "@angular/core";
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from "@angular/common/http";
import { AuthService } from "./auth.service";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

const merchantId = environment.merchantId;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.get("skip")) {
      const authToken = this.authService.getToken();
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + authToken
        },
        params: req.params.set('merchant_id', merchantId)
      });
    }

    return next.handle(req);
  }
}
