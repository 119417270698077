import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Account, UpdatePassword } from '../models/account';
import { OrderHistory } from '../models/order-history';
import { OrderStatus } from '../models/order-status';
import { HistoryParams } from '../../components/sections/transaction-history/transaction-history.component';
import { BaseService } from './base.service';

const endpoint = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  account(): Observable<any> {
    return this.http.get<Account>(`${endpoint}/api/account`);
  }

  orderHistory(params: HistoryParams): Observable<any> {
    return this.http.get<OrderHistory>(
      `${endpoint}/api/account/order-history`,
      {
        headers: this.headers,
        params: {
          ...this.cleanParams(params),
        },
      }
    );
  }

  orderStatus(): Observable<any> {
    return this.http.get<OrderStatus>(
      `${endpoint}/api/constant?for=order_status`
    );
  }

  updatePassword(data: UpdatePassword): Observable<any> {
    return this.http.post(`${endpoint}/api/account/update-password`, data);
  }

  logout(): Observable<any> {
    return this.http.post(`${endpoint}/api/account/logout`, {});
  }
}
