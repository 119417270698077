import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {faLongArrowRight} from "@fortawesome/free-solid-svg-icons/faLongArrowRight";

// import Swiper core and required modules
import SwiperCore, {SwiperOptions, Virtual} from "swiper";
import {SwiperComponent} from "swiper/angular";
import {Article} from "../../../api/models/article";

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss']
})
export class LatestNewsComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @Input() data: Article[] = [];

  faLongArrowRight = faLongArrowRight;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: false,
    pagination: {
      enabled: false
    },
    scrollbar: {
      draggable: true
    },
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
        spaceBetween: 25
      },
    }
  };

  /*data = [
    {
      title: 'Exclusive: ‘Our aim is just insane’ says Paper Rex f0rsakeN',
      image: 'assets/images/articles/article-1.png',
      link: '#',
      timer: '16 jam yang lalu'
    },
    {
      title: 'Vexana, Faramis, and Leomord are deadlier than ever in Rise of the...',
      image: 'assets/images/articles/article-2.png',
      link: '#',
      timer: '1 hari yang lalu'
    },
    {
      title: 'Leaks prove Kung Fu Panda x Mobile Legends collaboration is...',
      image: 'assets/images/articles/article-3.png',
      link: '#',
      timer: '4 hari yang lalu'
    }
  ]*/

  constructor() { }

  ngOnInit(): void {
  }

}
