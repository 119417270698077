import { Component, Input, OnInit } from '@angular/core';
import { Banner } from '../../../api/models/banner';

@Component({
  selector: 'app-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss'],
})
export class BannerSliderComponent implements OnInit {
  @Input() data: Banner[] = [];

  constructor() {}

  ngOnInit(): void {}
}
