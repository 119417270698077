import { Component, OnInit } from '@angular/core';
import { Product } from '../../../api/models/product';
import { BaseParams, sortedBy } from '../../../api/models/base-param';
import { ProductService } from '../../../api/services/product.service';
import { CategoryService } from '../../../api/services/category.service';
import { Category } from '../../../api/models/category';

export interface ProductParams extends BaseParams {
  category?: number | null;
}

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss'],
})
export class GameListComponent implements OnInit {
  products: Product[] = [];
  categories: Category[] = [{ id: 0, name: 'All' }];
  selectedCategory: number = 0;
  totalData: number = 0;
  isLoading: boolean = true;
  productParams: BaseParams = {
    per_page: 12,
    page: 1,
    order_by: 'name',
    sorted_by: 'asc',
  };

  constructor(
    private homeService: ProductService,
    private categoryService: CategoryService
  ) {}

  loadProducts = (isLoadMore = false) => {
    const params: ProductParams = {
      ...this.productParams,
      category: this.selectedCategory || undefined,
    };
    this.homeService.all(params).subscribe({
      next: (res) => {
        const { data, meta } = res;
        if (isLoadMore) {
          this.products = [...this.products, ...(data as Array<Product>)];
        } else {
          this.products = data as Array<Product>;
          this.isLoading = false;
        }
        this.totalData = meta.total;
      },
      complete: () => {},
    });
  };

  loadCategory(): void {
    this.categoryService.all().subscribe({
      next: (res) => {
        const { data } = res;
        this.categories = [...this.categories, ...data];
      },
      complete: () => {},
    });
  }

  ngOnInit(): void {
    this.loadCategory();
    this.loadProducts();
  }

  disabledShowMore = (): boolean => {
    const diff =
      this.totalData - this.productParams.per_page * this.productParams.page;
    return diff <= 0;
  };

  changeCategory(category: number): void {
    if (this.productParams.page > 1) this.productParams.page = 1;
    this.selectedCategory = category;
    this.isLoading = true;
    this.loadProducts();
  }

  changeSort(sortedBy?: sortedBy): void {
    if (this.productParams.page > 1) this.productParams.page = 1;
    this.productParams.sorted_by = sortedBy;
    this.isLoading = true;
    this.loadProducts();
  }

  loadMore(): void {
    this.productParams.page += 1;
    this.loadProducts(true);
  }
}
