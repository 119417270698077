<ng-container>
  <div *ngIf="!isRegistered">
    <div class="header-form">
      <h4 class="title">Daftar</h4>
      <p class="subtitle">
        Sudah punya akun? <a [routerLink]="['/auth/login']">Masuk</a>
      </p>
    </div>

    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          placeholder="Masukkan email Anda"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
          (ngModelChange)="errors.email = formErrorMessage.email"
        >
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors">{{errors.email}}</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Password</label>

        <div class="password-input-wrapper">
          <input
            id="password"
            type="password"
            class="form-control"
            placeholder="Buat password baru"
            formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
            (ngModelChange)="errors.password = formErrorMessage.password"
            showHideInput
          >
          <fa-icon
            class="icon"
            [icon]="isHidden ? faEye : faEyeSlash"
            showHideTrigger="password"
          ></fa-icon>
        </div>
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors">{{errors.password}}</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Ketik Ulang Password</label>

        <div class="password-input-wrapper">
          <input
            id="password-confirmation"
            type="password"
            class="form-control"
            placeholder="Ketik ulang konfirmasi password"
            formControlName="password_confirmation"
            [ngClass]="{ 'is-invalid': submitted && f['password_confirmation'].errors }"
            (ngModelChange)="errors.password_confirmation = formErrorMessage.password_confirmation"
            showHideInput
          >
          <fa-icon
            class="icon"
            [icon]="isHiddenConPassword ? faEye : faEyeSlash"
            showHideTrigger="password-confirmation"
          ></fa-icon>
        </div>

        <div *ngIf="submitted && f['password_confirmation'].errors" class="invalid-feedback">
          <div *ngIf="f['password_confirmation'].errors">{{errors.password_confirmation}}</div>
        </div>
      </div>

      <p>
        Dengan mendaftar, saya menyetujui <a [routerLink]="['/terms-and-condition']" class="default-link">Syarat dan Ketentuan</a> serta <a [routerLink]="['/privacy-policy']" class="default-link">Kebijakan Privasi</a>
      </p>

      <div class="full-btn-wrapper mt-4">
        <button type="submit" class="btn btn-main btn-full">
          <span *ngIf="!loading">Daftar</span>
          <div class="spinner-border" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="isRegistered" class="success-wrapper">
    <img class="icon" src="../../../../../assets/images/icon/check.png" alt="check icon">

    <h3 class="title">
      Verifikasi Email Dikirim ke Email Anda
    </h3>
    <p class="desc">
      Klik tautan di email verifikasi untuk menyelesaikan proses verifikasi. Silakan periksa folder JUNK atau SPAM jika tidak dapat menemukannya di folder INBOX.
    </p>
  </div>
</ng-container>
