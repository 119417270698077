import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ShowHideService } from 'ngx-show-hide-password';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Account } from '../../../api/models/account';
import { AccountService } from '../../../api/services/account.service';
import { ToastService } from '../../../services/toast.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-setting-account',
  templateUrl: './setting-account.component.html',
  styleUrls: ['./setting-account.component.scss'],
})
export class SettingAccountComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  form!: FormGroup;
  loading = false;
  submitted = false;

  isHiddenOldPassword = true;
  isHiddenNewPassword = true;

  errors = {
    old_password: null,
    new_password: null,
  };

  account = {
    email: '',
  };
  constructor(
    private service: ShowHideService,
    private fb: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private errorService: ErrorService,
    private toastService: ToastService
  ) {
    this.service
      .getObservable('old_password')
      .pipe(untilDestroyed(this))
      .subscribe((show) => {
        this.isHiddenOldPassword = !show;
      });

    this.service
      .getObservable('new_password')
      .pipe(untilDestroyed(this))
      .subscribe((show) => {
        this.isHiddenNewPassword = !show;
      });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      old_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
        ],
      ],
      new_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
        ],
      ],
    });

    this.getAccount();
  }

  ngOnDestroy(): void {}

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.loading = true;
    this.submitted = true;
    if (this.form?.invalid) {
      this.loading = false;
      return;
    }

    this.errors = {
      old_password: null,
      new_password: null,
    };

    this.accountService
      .updatePassword({
        ...this.form.value,
        new_password_confirmation: this.form.value.new_password,
      })
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.submitted = false;

          this.form.reset();
          this.form.markAsUntouched();
          this.toastService.show({
            text: 'Password successfully changed',
            classname: 'bg-success text-light',
          });
        },
        error: (e) => {
          if (e.error.errors) {
            let errors = this.errorService.transformToKeyValue(e.error.errors);

            this.errors = {
              ...this.errors,
              ...errors,
            };

            Object.keys(errors).map((objectKey) => {
              this.form.controls[objectKey].setErrors({ incorrect: true });
            });

            this.toastService.show({
              text: e.error.message,
              classname: 'bg-danger text-light',
            });
          }

          this.loading = false;
        },
      });
  }

  getAccount() {
    this.accountService.account().subscribe({
      next: (res: Account) => {
        this.account = res.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
