import { Component, OnInit, OnDestroy } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {ShowHideService} from "ngx-show-hide-password";
import {untilDestroyed} from "ngx-take-until-destroy";
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import Validation from "../../../../utils/validation";
import {AuthenticationService} from "../../../../api/services/authentication.service";
import {environment} from "../../../../../environments/environment";
import {ErrorService} from "../../../../services/error.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  isHidden = true;
  isHiddenConPassword = true;

  isRegistered = false;

  formErrorMessage = {
    email: 'Anda telah memasukkan alamat email yang tidak valid',
    password: 'Min. 8 karakter (terdiri dari huruf BESAR, kecil, dan angka)',
    password_confirmation: 'Min. 8 karakter (terdiri dari huruf BESAR, kecil, dan angka)'
  }

  form!: FormGroup;
  loading = false;
  submitted = false;
  errors = this.formErrorMessage;

  constructor(
    private service: ShowHideService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService
  ) {
    this.service
      .getObservable("password")
      .pipe(untilDestroyed(this))
      .subscribe(show => {
        this.isHidden = !show;
      });

    this.service
      .getObservable("password-confirmation")
      .pipe(untilDestroyed(this))
      .subscribe(show => {
        this.isHiddenConPassword = !show;
      });
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(40),
            Validation.strong
          ]
        ],
        password_confirmation: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'password_confirmation')]
      }
    )
  }

  ngOnDestroy(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.loading = true;
    this.submitted = true;
    if (this.form?.invalid) {
      this.loading = false;
      return;
    }

    this.authenticationService.register({
      ...this.form.value,
      url: environment.appUrl+'/auth/register/success'
    }).subscribe({
      next: (res) => {
        this.loading = false;
        this.isRegistered = true;
      },
      error: (e) => {
        if (e.error.errors) {
          let errors = this.errorService.transformToKeyValue(e.error.errors)

          this.errors = {
            ...this.errors,
            ...errors
          }

          Object.keys(errors).map((objectKey) => {
            this.form.controls[objectKey].setErrors({'incorrect': true});
          });
        }

        this.loading = false;
      }
    });
  }
}
