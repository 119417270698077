<ng-container>
  <div class="auth-layout">
    <div class="container">
      <div class="inner-section">
        <div class="box-form">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-container>
