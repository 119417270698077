<ng-container>
  <div class="not-found-page">
    <img
      class="background"
      src="assets/images/404/background.png"
      alt="background 404"
    />
    <div class="inner-section">
      <div class="container">
        <div class="content">
          <div class="info">
            <h1 class="title">Halaman Tidak Ditemukan</h1>

            <h3 class="subtitle">
              Halaman yang Anda cari tidak dapat ditemukan.
            </h3>

            <button type="button" class="btn btn-main" [routerLink]="['/']">
              Kembali ke Halaman Utama
            </button>
            <img
              class="robot"
              src="assets/images/404/robot.png"
              alt="robot 404"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
