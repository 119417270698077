import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faSearch, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { BaseParams } from '../../../../api/models/base-param';
import { ProductService } from '../../../../api/services/product.service';
import { Product } from '../../../../api/models/product';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchElement', { static: false }) searchElement?: ElementRef;
  faSearch = faSearch;
  faAngleDown = faAngleDown;

  productResults: Product[] = [];
  totalData: number = 0;
  searchResultActive: boolean = false;
  searchFocus: boolean = false;
  isLoadingSearch: boolean = false;

  productParams: BaseParams = {
    per_page: 6,
    page: 1,
    search: '',
  };
  searchSubscription?: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (location.pathname === '/result' && params['search']) {
        this.productParams.search = params['search'];
      }
    });

    this.router.events.subscribe((_) => {
      this.handleBlurSearch();
    });
  }

  hideLoginButton(): boolean {
    return this.authService.isLoggedIn || location.pathname === '/auth/login';
  }

  loadProducts = () => {
    this.searchSubscription = this.productService
      .all(this.productParams)
      .subscribe({
        next: (res) => {
          if (this.searchFocus) {
            const { data, meta } = res;
            this.productResults = data as Array<Product>;
            this.totalData = meta.total;
            this.searchResultActive = true;
          }
        },
        error: (e) => {},
        complete: () => (this.isLoadingSearch = false),
      });
  };

  debounceSearch = _.debounce((): void => {
    this.searchSubscription?.unsubscribe();
    this.loadProducts();
  }, 200);

  handleSearch(value: string) {
    this.productParams.search = value.trim();
    if (this.productParams.search.length > 0) {
      this.isLoadingSearch = true;
      this.debounceSearch();
    } else {
      this.searchResultActive = false;
    }
  }

  handleBlurSearch() {
    this.searchSubscription?.unsubscribe();
    this.searchFocus = false;

    setTimeout(() => {
      this.searchResultActive = false;
      if (location.pathname !== '/result') this.productParams.search = '';
      this.productResults = [];
    }, 200);
  }

  onSearchFocus(event: any) {
    this.searchFocus = !!event;
  }

  handleSearchEnter() {
    if (this.productParams.search?.length ?? 0 > 0) {
      this.searchResultActive = false;

      if (location.pathname.includes('/result')) {
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.navigateToResult();
          });
      } else this.navigateToResult();

      this.searchElement?.nativeElement.blur();
    }
  }

  navigateToResult() {
    this.router.navigate(['/result'], {
      queryParams: {
        search: this.productParams.search,
      },
    });
  }

  handleResultClick(permalink: string) {
    if (location.pathname.includes('/purchase')) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/purchase', permalink]);
      });
    } else this.router.navigate(['/purchase', permalink]);
  }
}
