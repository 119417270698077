<ng-container>
  <div class="spinner-wrapper" *ngIf="isLoading">
    <div class="spinner-grow spinner-orange" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <ng-content></ng-content>
  </div>
</ng-container>
