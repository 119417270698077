import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from '../../../api/models/article';
import { ArticleService } from '../../../api/services/article.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: false,
    pagination: {
      enabled: false,
    },
    scrollbar: {
      draggable: true,
    },
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
    },
  };

  article: Article = {
    id: 0,
    title: '',
    content: '',
    photo_id: 0,
    date: '',
    is_active: 1,
    permalink: '',
    photo_link: '',
  };
  permalink: string = '';
  href: string = '';
  whatsappText: string = '';
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.href = window.location.href;
    this.whatsappText = encodeURIComponent(
      `Check out this article \n ${this.href}`
    );

    this.route.params.subscribe((params) => {
      this.permalink = params['permalink'];
    });

    this.loadArticle();
  }

  loadArticle = () => {
    this.articleService.get(this.permalink).subscribe({
      next: (res) => {
        const { data } = res;
        this.article = data as Article;
        this.isLoading = false;
        this.metaService.updateTitle(this.article.title);
      },
      error: (e) => {
        if (e.status === 500) this.router.navigate(['/article']);
      },
    });
  };

  copyToClipboard() {
    navigator.clipboard.writeText(this.href).then(
      () => console.log('success copy'),
      (err) => console.error('error copy: ', err)
    );
  }
}
