<div class="latest-news-wrapper">
  <div class="header-wrapper">
    <h3 class="default-title">Berita Terbaru</h3>

    <a [routerLink]="['/article']" class="text-link">
      <u>Lihat Semua</u>
    </a>
  </div>

  <div class="latest-news-list">
    <swiper
      #swiper
      [virtual]="true"
      [config]="config"
    >
      <ng-template swiperSlide *ngFor="let item of data">
        <app-card-news
          link="/article/{{item.permalink}}"
          [image]="item.photo_link"
          [title]="item.title"
          [timer]="item.date"
        ></app-card-news>
      </ng-template>
    </swiper>
  </div>
</div>
