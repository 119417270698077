import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../../services/meta.service';
import { Product } from '../../../api/models/product';
import { BaseParams } from '../../../api/models/base-param';
import { ProductService } from '../../../api/services/product.service';
import { ArticleService } from '../../../api/services/article.service';
import { Article } from '../../../api/models/article';
import { PageService } from '../../../api/services/page.service';
import { PageHome } from '../../../api/models/page-home';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showcaseParams: BaseParams = {
    per_page: 40,
    page: 1,
  };
  isLoadingPage = true;
  isLoadingShowcase = true;

  showcases: Product[] = [];
  articles: Article[] = [];
  data: PageHome = {
    meta_title: '',
    meta_keyword: '',
    meta_description: '',
    banners: [],
    whys: [],
  };

  constructor(
    private metaService: MetaService,
    private productService: ProductService,
    private articleService: ArticleService,
    private pageService: PageService
  ) {
    this.metaService.updateTitle('Home');
  }

  ngOnInit(): any {
    this.getData();
    this.getShowcases();
    this.getArticles();
  }

  getData(): void {
    this.pageService.page('home').subscribe({
      next: (res) => {
        this.data = res.data as PageHome;
        this.isLoadingPage = false;
      },
      error: (e) => {},
    });
  }

  getArticles(): void {
    this.articleService.highlight().subscribe({
      next: (res) => {
        this.articles = res.data;
      },
      error: (e) => {},
    });
  }

  getShowcases(): void {
    this.productService.showcase(this.showcaseParams).subscribe({
      next: (res) => {
        this.showcases = res.data as Array<Product>;
        this.isLoadingShowcase = false;
      },
      error: (e) => {},
    });
  }
}
