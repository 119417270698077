import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { BaseParams } from '../models/base-param';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  all(params: BaseParams): Observable<any> {
    return this.http.get(`${this.endpoint}/api/product`, {
      headers: this.headers,
      params: {
        ...this.cleanParams(params),
        with: ['photo'],
      },
    });
  }

  get(permalink: string): Observable<any> {
    return this.http.get(`${this.endpoint}/api/product/${permalink}`)
  }

  showcase(params?: BaseParams): Observable<any> {
    return this.http.get(`${this.endpoint}/api/product-popular`, {
      headers: this.headers,
      params: {
        ...this.cleanParams(params),
      },
    });
  }
}
