import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../api/services/page.service';
import { PageTermCondition } from '../../../api/models/page-term-condition';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss'],
})
export class TermsAndConditionComponent implements OnInit {
  data: PageTermCondition = {
    meta_title: '',
    meta_keyword: '',
    meta_description: '',
    content: '',
    last_updated_at: '',
  };
  isLoadingPage = true;
  constructor(
    private pageService: PageService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.pageService.page('terms-condition').subscribe({
      next: (res) => {
        this.data = res.data as PageTermCondition;
        this.metaService.updateTitle(this.data.meta_description);
        this.isLoadingPage = false;
      },
      error: (e) => {},
    });
  }
}
