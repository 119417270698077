<div class="game-list">
  <div class="header-wrapper">
    <div class="category-wrapper">
      <div>
        <ul class="option-list">
          <li class="item" *ngFor="let category of categories">
            <button type="button" class="btn-option" [ngClass]="{'active': selectedCategory === category.id}" (click)="changeCategory(category.id)">{{category.name}}</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="sort-wrapper">
      <select class="form-select" [(ngModel)]="productParams.sorted_by" (change)='changeSort(productParams.sorted_by)'>
        <option value="asc">Urutan A ke Z</option>
        <option value="desc">Urutan Z ke A</option>
      </select>
    </div>
  </div>

  <app-spinner [isLoading]="isLoading">
    <div class="game-show-list">
      <app-card-game
        *ngFor="let item of products"
        [name]="item.name"
        [image]="item.photo?.url ?? null"
        [link]="'/purchase/'+item.permalink"
      ></app-card-game>
    </div>

    <div class="show-more" *ngIf="!disabledShowMore()">
      <button type="button" class="btn btn-outline-gray btn-lg" (click)="loadMore()">Muat lebih banyak</button>
    </div>
  </app-spinner>
</div>
