<ng-container>
  <app-spinner [isLoading]="isLoading">
    <div class="article-detail-page">
      <div class="container">
        <div class="header-wrapper">
          <div class="date">{{article.date | date}}</div>
          <h1 class="default-title">{{article.title}}</h1>

          <div class="shares-wrapper">
            <a class="share-item facebook" href="https://www.facebook.com/sharer.php?u={{href}}" target="_blank">
              <img src="/assets/svg/facebook.svg" alt="facebook" />
            </a>
            <a class="share-item twitter" href='https://twitter.com/intent/tweet?text={{article.title}}&url={{href}}' target="_blank">
              <img src="/assets/svg/twitter.svg" alt="twitter" />
            </a>
            <a class="share-item whatsapp" href="https://api.whatsapp.com/send?text={{whatsappText}}" target="_blank">
              <img src="/assets/svg/whatsapp.svg" alt="whatsapp" />
            </a>
            <a class="share-item link" (click)="copyToClipboard()" placement="top" ngbTooltip="Copy to clipboard">
              <img src="/assets/svg/link.svg" alt="link" />
            </a>
          </div>
        </div>

        <div class="body-wrapper">
          <div class="banner-image-wrapper">
            <img src="{{article.photo_link}}" alt="banner">
          </div>
          <div class="body-container" [innerHTML]="article.content"></div>
        </div>

        <div class="related-article-wrapper">
          <h3 class="default-title">Artikel Terkait</h3>
          <div class="related-article-list">
            <swiper
              #swiper
              [virtual]="true"
              [config]="config"
            >
              <ng-template swiperSlide *ngFor="let item of article.related || []">
                <app-card-news
                  link="/article/{{article.permalink}}"
                  [image]="article.photo_link"
                  [title]="article.title"
                  [timer]="article.date"
                ></app-card-news>
              </ng-template>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </app-spinner>
</ng-container>

