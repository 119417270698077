import {NavigationEnd, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {filter} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class RoutingService {
  private history: string[] = [];

  constructor(
    private router: Router
  ) {}


  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.history = [...this.history, event.urlAfterRedirects];
        }
      });
  }

  public removeHistory(): void {
    this.history = []
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }
}
