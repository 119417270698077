<div class="showcase">
  <div class="header-wrapper">
    <h3 class="default-title">{{title}}</h3>

    <div class="slider-control">
      <button class="btn-control prev" (click)="slidePrev()">
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
      </button>
      <button class="btn-control next" (click)="slideNext()">
        <fa-icon [icon]="faChevronRight" class="icon"></fa-icon>
      </button>
    </div>
  </div>

  <app-spinner [isLoading]="isLoading">
    <div class="showcase-slider">
      <swiper
        #swiper
        [virtual]="true"
        [config]="config"
      >
        <ng-template swiperSlide *ngFor="let game of list">
          <app-card-game
            [name]="game.name"
            [image]="game.photo?.url ?? null"
            [link]="'/purchase/'+game.permalink"
          ></app-card-game>
        </ng-template>
      </swiper>
    </div>
  </app-spinner>
</div>
