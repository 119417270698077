import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

const endpoint = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class VariantService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  all(params: any): Observable<any> {
    return this.http.get(`${endpoint}/api/variant`, {
      params: params
    });
  }
}
