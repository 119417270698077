import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../../services/meta.service';
import { Article } from '../../../api/models/article';
import { BaseParams } from '../../../api/models/base-param';
import { Product } from '../../../api/models/product';
import { ProductParams } from '../../sections/game-list/game-list.component';
import { ArticleService } from '../../../api/services/article.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  articles: Article[] = [];
  totalData: number = 0;
  isLoading: boolean = true;
  articleParams: BaseParams = {
    per_page: 12,
    page: 1,
  };

  constructor(
    private metaService: MetaService,
    private articleService: ArticleService
  ) {
    this.metaService.updateTitle('Artikel');
  }

  ngOnInit(): void {
    this.loadArticles();
  }

  loadArticles = (isLoadMore = false) => {
    this.articleService.all(this.articleParams).subscribe({
      next: (res) => {
        const { data, meta } = res;
        if (isLoadMore) {
          this.articles = [...this.articles, ...(data as Article[])];
        } else {
          this.articles = data as Article[];
          this.isLoading = false;
        }
        this.totalData = meta.total;
      },
      complete: () => {},
    });
  };

  disabledShowMore = (): boolean => {
    const diff =
      this.totalData - this.articleParams.per_page * this.articleParams.page;
    return diff <= 0;
  };

  loadMore(): void {
    this.articleParams.page += 1;
    this.loadArticles(true);
  }
}
