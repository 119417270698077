import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService{
  constructor(private http: HttpClient) {
    super();
  }

  promo(data: any): Observable<any> {
    return this.http.post(`${this.endpoint}/api/order-promo`, data)
  }

  createOrder(data: any): Observable<any> {
    return this.http.post(`${this.endpoint}/api/order`, data)
  }

  validateUser(data: any): Observable<any> {
    return this.http.post(`${this.endpoint}/api/validate-user`, data)
  }
}
